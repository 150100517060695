import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'

import { Layout, Block, Ticker } from '../components'
import { container, padding, colours, type, bgImage } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import { parseACF, getImageCrop } from '../utils'

const Index = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [videoLoaded, setVideoLoaded] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const detectMobile = useBreakpoint('phone')

    // Check if Mobile

    useMount(() => {
        setIsMobile(detectMobile)
    })

    useEffect(() => {
        setIsMobile(detectMobile)
    }, [detectMobile])

    // Hero Text

    const renderHeroText = () => {
        if (!data.header_text) return

        return (
            <HeroText dangerouslySetInnerHTML={{ __html: data.header_text }} />
        )
    }

    // Hero Video

    const renderHeroVideo = () => {
        if (!data.header_video) return

        return (
            <HeroVideo whileHover={{ borderRadius: 30 }}>
                <Video
                    playing
                    muted
                    loop
                    playsinline
                    url={data.header_video}
                    width={'100%'}
                    height={'100%'}
                    //onReady={() => setVideoLoaded(true)}
                    //style={{
                    //    opacity: videoLoaded ? 1 : 0,
                    //}}
                />
            </HeroVideo>
        )
    }

    // Hero Image

    const renderHeroImage = () => {
        if (!data.header_image) return

        return (
            <HeroImage
                key={data.header_image.sizes.full_width}
                src={data.header_image.sizes.full_width}
            >
                {(src, loading) => {
                    return (
                        <HeroImageBg
                            image={src}
                            style={{
                                opacity: loading ? 0 : 1,
                                ...getImageCrop(data.header_image),
                            }}
                        />
                    )
                }}
            </HeroImage>
        )
    }

    // Grid

    const renderGrid = () => {
        if (!data.projects) return

        const items = data.projects.map((block, i) => {
            return (
                <Block
                    layout={'grid-item'}
                    {...block}
                    key={i}
                    display
                    styles={GridItem}
                />
            )
        })

        return <Grid id="projects">{items}</Grid>
    }

    return (
        <Layout meta={data && data.seo} useBlackCharacters={true}>
            <Container {...variants}>
                <HeroWrapper>
                    {renderHeroText()}

                    {data.header_video_or_image == 'video'
                        ? renderHeroVideo()
                        : renderHeroImage()}
                </HeroWrapper>

                {/* {renderGrid()} */}

                {data.operations_text && (
                    <Operations>
                        <Text>Operations</Text>
                        <Ticker
                            content={data.operations_text}
                            velocity={isMobile ? 10 : 16}
                            direction={'rtl'}
                            hoverColor={'red'}
                        />
                    </Operations>
                )}
            </Container>
        </Layout>
    )
}

// Shared`

const Video = styled(ReactPlayer)``
const GridItem = styled.div``
const Text = styled.p``

// Container

const Container = styled(motion.div)`
    ${container}
    ${padding}
	flex-direction: column;
`

// Hero Text

const HeroWrapper = styled.div`
    position: relative;
    margin-top: 100px;
    margin-bottom: 80px;

    ${media.tablet`
		margin-bottom: 40px;
		margin-top: 40px;
	`}
`

const HeroText = styled.h1`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 40;

	${type.heading1}
    color: ${colours.red};
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
	pointer-events: none;
	max-width: 1200px;

	${media.tablet`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		padding: 100px 0;
	`}
`

// Hero Video

const HeroVideo = styled(motion.div)`
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    z-index: 20;
    background: ${colours.white};

    ${media.tablet`
		width: 100vw;
		margin-left: -20px;
		margin-right: -20px;
	`}

    ${Video} {
        display: flex;

        video {
            max-height: 80vh;
            object-fit: cover;
        }
    }
`

// Hero Image

const HeroImage = styled(ProgressiveImage)`
    overflow: hidden;
`

const HeroImageBg = styled.div`
    ${bgImage};
    background-image: url(${(props) => props.image});
    transition: opacity 0.45s ease;
    height: 80vh;

    ${media.tablet`
        display: none;
		width: 100vw;
		margin-left: -30px;
		margin-right: -30px;
	`}
`

// Grid

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px 12px;
    padding-bottom: 40px;

    ${media.tablet`
		flex-direction: column;
	`}

    > * {
        &:nth-child(2n) {
            .content {
                text-align: right;

                ${media.tablet`
                    text-align: center;
                `}
            }
        }
    }
`

// Operations

const Operations = styled.div`
    margin-left: -60px;
    margin-right: -60px;
    background: ${colours.white};
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden;

    ${media.tablet`
		margin-left: -30px;
		margin-right: -30px;
	`}

    ${Text} {
        font-size: 24px;
        color: ${colours.red};
        ${padding}
        padding-left: 60px;

        ${media.tablet`
            margin-left: 12px;
        `}
    }
`

// Animation

const duration = 0.8
const variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: duration,
            delay: duration,
            when: 'beforeChildren',
        },
    },
    exit: {
        opacity: 0,
        transition: { duration: duration },
    },
}

// Query

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
